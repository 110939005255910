<template>
  <div :class="classObj" class="layout-wrapper">
    <Navbar v-if="settings.showTopNavbar" />
    <!--right container-->
    <div id="main-right" class="main-container">
      <!--left side-->
      <!-- <hamburger
        v-if="settings.showHamburger"
        :is-active="opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      /> -->
      <div class="sidebar-container">
        <el-scrollbar height="calc(100dvh-70px)">
          <Sidebar
            v-if="settings.showLeftMenu && _isManager"
            :managerTF="_isManager.branchManager"
          />
        </el-scrollbar>

        <div id="sideBarOpenArea" @click.prevent="toggleSideBar" class="group">
          <template v-if="appStore.sidebar.opened">
            <el-icon class="opacity-0 group-hover:opacity-100 transition duration-300"
              ><ArrowLeft
            /></el-icon>
          </template>
          <template v-else>
            <el-icon><ArrowRight /></el-icon>
          </template>
        </div>
      </div>
      <TagsView v-if="settings.showTagsView" />
      <AppMain />
    </div>
  </div>
</template>

<script setup name="Layout">
import { Sidebar, Navbar, AppMain, TagsView } from './components'
import { useUserStore } from '@/store/user'
//import ResizeHook to   listen  page size that   open or close
import ResizeHook from './hook/ResizeHandler'
import { useAppStore } from '@/store/app'
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import { getIsManager } from '@/utils/auth'
// ResizeHook()

const _isManager = ref(JSON.parse(getIsManager()))
// console.log("222")
const appStore = useAppStore()

const opened = computed(() => {
  return appStore.sidebar.opened
})
const settings = computed(() => {
  return appStore.settings
})
const classObj = computed(() => {
  return {
    closeSidebar: !opened.value,
    hideSidebar: !settings.value.showLeftMenu
  }
})
const toggleSideBar = () => {
  appStore.sidebar.opened = !appStore.sidebar.opened
  // 打開前檢查一下是否是店長，傳給Sidebar
  _isManager.value = JSON.parse(getIsManager())
  // console.log(_isManager.value)
}
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: var(--side-bar-width);
  position: relative;
  border-left: 1px solid var(--layout-border-left-color);
}
.sidebar-container {
  transition: width 0.28s;
  width: var(--side-bar-width) !important;
  background-color: var(--el-menu-bg-color);
  height: 100%;
  position: fixed;
  font-size: 0;
  // top: 0;
  top: var(--nav-bar-height);
  left: 0;
  // z-index: 1001;
  overflow: hidden;
}
.closeSidebar {
  .sidebar-container {
    width: var(--close-sidebar-width) !important;
    left: calc(-1 * var(--close-sidebar-width));
  }
  .main-container {
    margin-left: 54px !important;
  }
  #sideBarOpenArea {
    width: var(--close-sidebar-width) !important;
    left: 0;
    background-color: var(--el-menu-bg-color);
  }
}
.hideSidebar {
  .sidebar-container {
    width: 0 !important;
  }
  .main-container {
    margin-left: 0;
  }
}
#sideBarOpenArea {
  width: calc(var(--close-sidebar-width) - 10px) !important;
  // background-color: var(--el-menu-bg-color);
  background-color: transparent;
  height: 100%;
  position: fixed;
  font-size: 0;
  // top: 0;
  top: var(--nav-bar-height);
  left: calc(var(--side-bar-width) - var(--close-sidebar-width) + 10px);
  // z-index: 1000;
  overflow: hidden;
  cursor: pointer;
  i {
    font-size: 1rem;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
}
</style>
