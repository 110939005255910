export default {
  route: {
    Dashboard: '首页',
    'Setting Switch': '设置文件测试',
    Guide: '引导页',
    ErrorLog: '错误日志',
    'ErrorLog Test': '错误日志测试',

    Nested: '路由嵌套',
    Menu1: '菜单1',
    'Menu1-1': '菜单 1-1',
    'Menu1-2': '菜单 1-2',
    'Menu1-2-1': '菜单 1-2-1',
    'Menu1-2-2': '菜单 1-2-2',
    'Menu1-3': '菜单 1-3',
    menu2: '菜单 2',
    Table: '表格',
    exportExcel: '导出Excel',
    importExcel: '导入Excel',
    CodeGenerator: '低代碼平台',
    ExternalLink: '外链',

    Charts: '图表',
    'Keyboard Chart': '键盘图表',
    'Line Chart': '折线图',
    'Mix Chart': '混合图表',
    'Echarts Demo': '综合实例',

    CRUD: '增删改查',
    'CRUD-Demo': '增删改查例子',
    'Import Export': '导入导出文件',
    'ImgAdd Pack': '图片上傳',
    'Writing Demo': '写法案例',
    'Hook-Demo': 'hooks例子',
    'Vuex-Demo': 'Vuex例子',
    'Mock-Demo': 'Mock例子',
    'Svg-Demo': 'Svg例子',
    'Parent-Children': '父子通信例子',
    'Keep-Alive': 'Keep-Alive',
    'RouterDemo-F': '路由跳转页面1',
    'RouterDemo-S': '路由跳转页面2',
    'Use Example': '使用例子',
    Directive: '自定义指令',
    Tinymce: 'Tinymce富文本',
    'I18n-Demo': 'i18n例子',
    dragPane: '拖拽面板',
    countTo: '數字动画',
    Permission: '权限',
    'Role Index': '角色权限',
    'Page Permission': '角色权限页面',
    'Directive Permission': '角色指令权限',
    'Code Index': 'codeArr权限',
    'Code Page': 'codeArr权限页面'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '個人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    username: '账号',
    password: '密碼',
    any: '随便填',
    thirdparty: '第三方登录',
    thirdpartyTips: '本地不能模拟，請结合自己业务进行模拟！！！'
  },
  documentation: {
    documentation: '文档',
    github: 'Github 地址'
  },
  permission: {
    addRole: '新增角色',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips:
      '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '確定',
    cancel: '取消'
  },
  guide: {
    description: '引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于',
    button: '打开引导'
  },
  components: {
    documentation: '文档',
    tinymceTips:
      '富文本是管理后台一個核心的功能，但同时又是一個有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
    dropzoneTips:
      '由于我司业务有特殊需求，而且要傳七牛 所以没用第三方，选择了自己封装。代碼非常的简单，具体代碼你可以在这里看到 @/components/Dropzone',
    stickyTips: '当页面滚动到预设的位置会吸附在顶部',
    backToTopTips1: '页面滚动到指定位置会在右下角出现返回顶部按钮',
    backToTopTips2:
      '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    imageUploadTips:
      '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。'
  },
  table: {
    dynamicTips1: '固定表头, 按照表头顺序排序',
    dynamicTips2: '不固定表头, 按照点击顺序排序',
    dragTips1: '默認顺序',
    dragTips2: '拖拽后顺序',
    title: '标题',
    importance: '重要性',
    type: '类型',
    remark: '点评',
    search: '搜索',
    add: '添加',
    export: '导出',
    reviewer: '审核人',
    id: '序号',
    date: '时间',
    author: '作者',
    readings: '阅读數',
    status: '状态',
    actions: '操作',
    edit: '编辑',
    publish: '发布',
    draft: '草稿',
    delete: '删除',
    cancel: '取 消',
    confirm: '確 定'
  },
  example: {
    warning:
      '创建和编辑页面是不能被 keep-alive 缓存的，因為keep-alive 的 include 目前不支持根据路由来缓存，所以目前都是基于 component name 来进行缓存的。如果你想类似的实现缓存效果，可以使用 localStorage 等浏览器缓存方案。或者不要使用 keep-alive 的 include，直接缓存所有页面。详情见'
  },
  errorLog: {
    tips: '請点击右上角bug小图标',
    description:
      '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一個小小的疏忽就导致整個页面的死锁。好在 Vue 官网提供了一個方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '請輸入文件名(默認excel-list)'
  },
  zip: {
    export: '导出',
    placeholder: '請輸入文件名(默認file)'
  },
  pdf: {
    tips: '这里使用   window.print() 来实现下载pdf的功能'
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体請参考文档。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  }
}
