<template>
  <div>
    <div id="Sidebar" class="reset-menu-style">
      <!--logo-->
      <Logo v-if="settings.sidebarLogo" :collapse="!isCollapse" />
      <!--router nav-->

      <el-scrollbar>
        <el-menu
          class="el-menu-vertical pb20"
          :default-active="activeMenu"
          :collapse="!isCollapse"
          :unique-opened="false"
          :collapse-transition="false"
          mode="vertical"
        >
          <template v-for="route in routes" v-if="_isManager">
            <template v-if="_isManager.branchManager">
              <sidebar-item :item="route" :base-path="route.path" />
            </template>
            <template v-else>
              <sidebar-item :item="route" v-if="!route.manager" :base-path="route.path" />
            </template>
          </template>
        </el-menu>
      </el-scrollbar>
    </div>
  </div>
</template>

<script setup>
import Logo from './Logo.vue'
import { useAppStore } from '@/store/app'
import { usePermissionStore } from '@/store/permission'
import SidebarItem from './SidebarItem.vue'
import { getIsManager } from '@/utils/auth'

const props = defineProps({
  managerTF: {
    type: Boolean,
    required: true
  }
}) //單純引用父元件資料

//导入配置文件
const appStore = useAppStore()
const settings = computed(() => {
  return appStore.settings
})
const route = useRoute()
const permissionStore = usePermissionStore()
const routes = computed(() => {
  return permissionStore.routes
})
const isCollapse = computed(() => {
  return appStore.sidebar.opened
})

const _isManager = ref(JSON.parse(getIsManager()))
// console.log(_isManager.branchManager)
watch(
  () => props.managerTF,
  (newValue, oldValue) => {
    // 如果上面傳來告知isManager有變動，就重新抓取是否是店長
    _isManager.value = JSON.parse(getIsManager())
  }
)
const activeMenu = computed(() => {
  const { meta, path } = route
  // if set path, the sidebar will highlight the path you set
  if (meta.activeMenu) {
    return meta.activeMenu
  }
  return path
})
</script>
<style lang="scss">
//fix open the item style issue
.el-menu-vertical {
  width: var(--side-bar-width);
}
</style>
