<template>
  <template v-if="!item.hidden">
    <template v-if="showSidebarItem(item.children, item)">
      <Link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <item :meta="onlyOneChild.meta || item.meta" />
          <template #title
            >{{ generateTitle(onlyOneChild.meta?.title) }}
            <div
              v-if="onlyOneChild.name === '點數管理' && branchPoints === 0"
              style="
                position: relative;
                margin-left: 12px;
                width: 5px;
                height: 5px;
                background-color: red;
                border-radius: 50%;
              "
            ></div
          ></template>
        </el-menu-item>
      </Link>
    </template>
    <el-sub-menu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template v-if="item.meta" #title>
        <item :meta="item.meta" />
        <span>{{ generateTitle(item.meta.title) }}</span>
      </template>
      <SidebarItem
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
      />
    </el-sub-menu>
  </template>
</template>

<script setup>
/*初始化参數比如引入组件，proxy,state等*/
import Link from './Link.vue'
import Item from './Item'
import { isExternal } from '@/utils/validate'
import path from 'path'

import { pointsFuncs } from '@/api/points'

const props = defineProps({
  //每一個router Item
  item: {
    type: Object,
    required: true
  },
  //用于判断是不是子Item,设置响应的样式
  isNest: {
    type: Boolean,
    default: false
  },
  //基础路径，用于拼接
  basePath: {
    type: String,
    default: ''
  }
})
//i18n
import useI18n from '@/hooks/useI18n'
import { onMounted } from 'vue'
const { generateTitle } = useI18n()
//显示sidebarItem 的情况
let onlyOneChild = ref(null)
const showSidebarItem = (children = [], parent) => {
  const showingChildren = children.filter((item) => {
    if (item.hidden) {
      return false
    } else {
      // Temp set(will be used if only has one showing child)
      onlyOneChild.value = item
      return true
    }
  })
  if (showingChildren.length === 1 && !parent?.alwaysShow) {
    return true
  }
  if (showingChildren.length === 0) {
    onlyOneChild.value = { ...parent, path: '', noChildren: true }
    return true
  }
  return false
}
const resolvePath = (routePath) => {
  if (isExternal(routePath)) {
    return routePath
  }
  if (isExternal(props.basePath)) {
    return props.basePath
  }
  return path.resolve(props.basePath, routePath)
}

// 取得點數列表
const branchPoints = ref(null)
const getPoint = async () => {
  try {
    const point = await pointsFuncs.total()
    // console.log('getPoint ===> ', point)
    branchPoints.value = point.data.point
  } catch (error) {
    console.log(error)
  }
}

onMounted(() => {
  if (onlyOneChild.value?.name === '點數管理') {
    // console.log('onlyOneChild.meta ===> ', onlyOneChild.value)
    getPoint()
  }
})
</script>
