<template>
  <div>
    <el-scrollbar ref="scrollRef">
      <!-- <el-button type="primary" @click="moveChatToOrder(123)">click</el-button> -->
      <!-- {{ roomData }} -->
      <div
        v-if="chatData?.messages"
        id="scroll__view"
        px-1
        flex
        flex-col
        gap-2
        pt-2
        px-3
        class="h-[calc(100%-54px)] pb-[58px]"
      >
        <div
          v-for="(message, index) in chatData.messages"
          :key="index"
          overflow-hidden
          flex
          :class="[{ 'justify-end': message.sender === userInfo.branchId }]"
        >
          <p
            m-0
            py-3
            px-3
            break-all
            w-max
            :class="[
              {
                message__store: message.sender !== userInfo.branchId,
                message__user: message.sender === userInfo.branchId,
                message__system: message.sender === 'system',
              },
            ]"
            class="rounded-[20px] max-w-[80%]"
          >
            <span v-if="message.sender === 'system'">系統訊息：</span
            >{{ message.content }}
          </p>
        </div>
      </div>
    </el-scrollbar>

    <div
      flex
      items-center
      gap-1
      sticky
      px-5
      py-3
      bottom-0
      class="bg-[#F8F9FD] z-1"
    >
      <el-input
        v-model.trim="newMessage"
        type="text"
        id="message-input"
        placeholder="Type your message..."
        @keydown.enter="handleEnterKey"
      />
      <el-button
        type="primary"
        :icon="ElementPlusIconsVue.Position"
        @click="sendMessage"
      />
    </div>
  </div>
</template>

<script setup>
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import {
  getDatabase,
  ref as storageRef,
  set,
  push,
  onValue,
  update,
  get,
  remove,
  orderByChild,
  equalTo,
} from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import { useAccountStore } from "@/store/branch";

const props = defineProps({
  roomData: {
    type: Object,
    default: () => {},
  },
});
const db = getDatabase();
const accountStore = useAccountStore();

const userInfo = computed(() => accountStore.userDetails);
const messages = ref([
  { text: "Hello there!", who: "store" },
  { text: "How are you doing?", who: "store" },
]);
const newMessage = ref("");

const chatData = ref({});
//取得聊天室資料
function getChatData() {
  const { branch_id: storeId, material_id } = props.roomData.store;
  // const { branchId: uid } = userInfo.value;
  const { branchId: uid } = props.roomData.user;

  console.log("getChatData", uid, storeId, material_id);

  const uids = [uid, storeId].sort();
  const chatRef = storageRef(
    db,
    `chatRooms/${storeId}/${material_id}/${uids[0]}_${uids[1]}`
  );

  onValue(chatRef, (snapshot) => {
    const data = snapshot.val();
    chatData.value = data;
    setTimeout(() => {
      scrollDrawer();
    }, 10);
    // console.log("onValue", data);
  });
}
getChatData();

// #region 發送聊天室資料
let isFirstRun = true;

async function writeUserData(content, type = "text") {
  const {
    branch_id: storeId,
    material_id,
    material_name,
  } = props.roomData.store;
  // const { branchId: uid } = userInfo.value;
  const { branchId: uid, employeeName } = props.roomData.user;

  const uids = [uid, storeId].sort();
  // 確保 chatroomID 存在
  const chatroomRef = storageRef(
    db,
    `chatRooms/${storeId}/${material_id}/${uids[0]}_${uids[1]}`
  );
  await update(chatroomRef, {
    online: {
      user: true,
      store: true,
    },
    uid,
    employeeName,
    material_name,
    storeId,
    productId: material_id,
  });

  // 最後，在 messages 中寫入新的訊息
  const chatRef = storageRef(
    db,
    `chatRooms/${storeId}/${material_id}/${uids[0]}_${uids[1]}/messages`
  );
  const newMessageRef = await push(chatRef);

  await set(newMessageRef, {
    type,
    key: uuidv4(),
    sender: userInfo.value.branchId,
    content,
    timestamp: Date.now(),
  });
}
//發送訊息
const sendMessage = async () => {
  const messageText = newMessage.value;
  if (messageText !== "") {
    messages.value.push({ text: messageText });
    // messages.value.push({ text: messageText, who: "user" });

    const content = newMessage.value;
    await writeUserData(content);

    newMessage.value = "";
    setTimeout(() => {
      scrollDrawer();
    }, 10);
  }
};
// 阻止在中文輸入的時候enter觸發 sendMessage
function handleEnterKey(e) {
  if (e.isComposing) return;
  sendMessage();
}
// #endregion

// #region 發送系統訊息
async function writeSystemData(content) {
  const {
    branch_id: storeId,
    material_id,
    material_name,
  } = props.roomData.store;

  console.log("props.roomData.store", props.roomData.store);
  const { branchId: uid, employeeName } = props.roomData.user;

  const uids = [uid, storeId].sort();
  // 確保 chatroomID 存在
  // const chatroomRef = storageRef(
  //   db,
  //   `chatRooms/${storeId}/${material_id}/${uids[0]}_${uids[1]}`
  // );

  // await update(chatroomRef, {
  //     online: {
  //       user: true,
  //       store: true,
  //     },
  //     uid,
  //     employeeName,
  //     material_id,
  //     material_name,
  //     storeId,
  //     productId: material_id,
  //   });

  // 最後，在 messages 中寫入新的訊息
  const chatRef = storageRef(
    db,
    `chatRooms/${storeId}/${material_id}/${uids[0]}_${uids[1]}/messages`
  );
  const newMessageRef = await push(chatRef);

  await set(newMessageRef, {
    type: "text",
    key: uuidv4(),
    sender: "system",
    content,
    timestamp: Date.now(),
  });
}
// #endregion

// #region 訂單成立後移動資料
async function moveChatToOrder(orderId) {
  const { branch_id: storeId, material_id } = props.roomData.store;
  const { branchId: uid } = props.roomData.user;

  const uids = [uid, storeId].sort();
  // 原始數據位置
  const originalPath = `chatRooms/${storeId}/${material_id}/${uids[0]}_${uids[1]}`;
  const originalRef = storageRef(db, originalPath);
  // 新位置
  const newLocation = `orders/${orderId}/chat`;
  const newRef = storageRef(db, newLocation);
  //通知訂單成立
  const notifyLocation = `notify/${storeId}/${orderId}`;
  const notifyRef = storageRef(db, notifyLocation);

  try {
    // 取得原始數據
    const snapshot = await get(originalRef);
    const data = snapshot.val();

    if (data) {
      //copy data to notifyRef
      await set(notifyRef, data);

      // 將數據寫入新位置
      await set(newRef, data);
      console.log("資料移動成功");

      await remove(originalRef);
      console.log("原始數據已刪除");
    } else {
      console.warn("原始數據為空，無需移動");
    }
  } catch (error) {
    console.error("資料移動失敗：", error);
  }
}
// #endregion

// 自動捲動到最底部
const scrollRef = ref(null);
function scrollDrawer() {
  const el = document.querySelector("#scroll__view");
  if (!el) return;
  setTimeout(() => {
    //let el scroll to bottom
    scrollRef.value.setScrollTop(10000);
  }, 100);
}

defineExpose({
  writeSystemData,
  moveChatToOrder,
  getChatData,
});
</script>

<style lang="scss" scoped></style>
