import request from "@/utils/axiosReq";
import { getForWorkOrder } from "@/utils/auth";

const loginStatus = JSON.parse(getForWorkOrder());
export const pointsFuncs = {
    list: async function(input,_page){
        const loginStatus = JSON.parse(getForWorkOrder());
        let _input = input;
        // 保固品牌列表
        return request({
            url: "/Point/point_log_list",
            method: "post",
            // isGuarantee : true , //呼叫保固的api
            data: {
                "page": _page,
                "items": 10,
                "pager" : true,
                whereCols : [
                    { headquarter_id : loginStatus.headquarter_id },
                    { branch_id : loginStatus.branch_id },
                ],
                rangeCols : [{
                    created_at : _input
                }],
                sortCols : [{"created_at":"desc"}],
            },
        });
    },
    total: async function(input){
        const loginStatus = JSON.parse(getForWorkOrder());
        let _input = input;
        // 保固品牌列表
        return request({
            url: "/Point/get_point",
            method: "post",
            // isGuarantee : true , //呼叫保固的api
            data: {
                headquarter_id : loginStatus.headquarter_id ,
                branch_id : loginStatus.branch_id
            },
        });
    }
}
