<template>
  <el-drawer
    v-model="chatDrawer"
    :with-header="false"
    class="bg-[#F8F9FD] my__drawer"
  >
    <div flex flex-col h-full class="max-h-[100dvh] h-full">
      <div
        shrink-0
        ring-b
        px-5
        py-3
        shadow-sm
        flex
        class="border-[#F0F1F5] max-h-[175px]"
      >
        <div>
          <div text-xl>
            你正在與<span class="text-primary">{{
              roomData.user.employeeName
            }}</span>
            進行對話
          </div>
          <ul list-outside pl-5 my-2>
            <li>
              <span>部品編號：{{ roomData.store.material_id }}</span>
            </li>
            <li>
              <span>部品名稱：{{ roomData.store.material_name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <ChatRoom :room-data="roomData" flex-1 overflow-auto overflow-y-hidden />
    </div>
  </el-drawer>
</template>

<script setup>
import ChatRoom from "@/components/layout/Chatroom.vue";

const props = defineProps({
  roomData: {
    type: Object,
    required: true,
  },
});
const chatDrawer = ref(false);

const showRoom = () => {
  chatDrawer.value = true;
};

defineExpose({
  showRoom,
  chatDrawer,
});
</script>

<style lang="scss" scoped>
:deep(.el-overlay .el-drawer) {
  .el-drawer__body {
    padding: 0px !important;
  }
}
</style>
