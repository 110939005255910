import request from '@/utils/axiosReq'

export function formItems(){
  return {
    getList : [
      { label : "ID" , prop : "managerId" },
      { label : "名稱" , prop : "managerName" },
      // { label : "" , prop : "managerPassword" },
      { label : "等級" , prop : "level"},
      // { label : "" , prop : "authToken" },
      // { label : "" , prop : "limitTime"},
      // { label : "" , prop : "createdAt"},
      // { label : "" , prop : "updatedAt"},
    ],
    add : [
      {
        prop : "managerId",// String	Manager Id
        placeholder : "ID",
        label : "ID",
      },
      {
        prop : "managerName",// String	Manager Name
        placeholder : "名稱",
        label : "名稱",
      },
      {
        prop : "managerPassword" ,// String	Manager Password
        placeholder : "密碼",
        label : "密碼",
        type : "password"
      },
      {
        prop : "level" ,// String	Manager Password
        placeholder : "等級",
        label : "等級",
        type : "number"
      },
    ],
    edit : [
      {
        prop : "managerId",// String	Manager Id
        placeholder : "ID",
        label : "ID",
        disabled : true
      },
      {
        prop : "managerName",// String	Manager Name
        placeholder : "名稱",
        label : "名稱",
      },
      {
        prop : "level" ,// String	Manager Password
        placeholder : "等級",
        label : "等級",
        type : "number"
      },
    ],
    resetPassword : [
      {
        prop : "managerId",// String	Manager Id
        placeholder : "ID",
        label : "ID",
        disabled : true
      },
      {
        prop : "managerPassword",// String	Manager Name
        placeholder : "密碼",
        label : "password",
      },
    ],
  }
}

// 管理員登入
export function LoginMangerReq(data) {
  return request({
    url: '/Manager/login/',
    data,
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getInfoReq() {
  return request({
    url: '/Manager/findOneByToken/',
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}
export function getList() {
  return request({
    url: '/Manager/findAll/',
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}
// 新增
export function add(inputData) {
  return request({
    url: '/Manager/createOne/',
    method: 'post',
    data : {
      managerId : inputData.managerId , 	// String	Manager Id
      managerName : inputData.managerName , 	// String	Manager Name
      managerPassword : inputData.managerPassword , 	// String	Manager Password
      level : inputData.level , //	Number Manager Level
    }
  })
}
// 修改
export function edit(inputData) {
  return request({
    url: '/Manager/setOne/',
    method: 'post',
    data : {
      managerId : inputData.managerId , 	// String Manager Id
      managerName : inputData.managerName , 	// String Manager Name
      managerPassword : inputData.managerPassword , 	// String Manager Password
      level : inputData.level ,  	//Number Manager Level
    }
  })
}
// 获取详情
export function getDetail(managerId) {
  return request({
    url: '/Manager/findOne/',
    method: 'post',
    data : {
      'managerId' : managerId
    }
  })
}
// 修改password
export function resetPassword(inputData) {
  return request({
    url: '/Manager/resetPassWord/',
    method: 'post',
    data : {
      managerId : inputData.managerId ,// String Manager Id
      managerPassword : inputData.managerPassword ,// String Manager Password
    }
  })
}
// 删除
export function del(inputData) {
  console.log("X")
  console.log(inputData)
  return request({
    url: '/Manager/deleteOne/',
    method: 'post',
    data : {
      managerId : inputData
    }
  })
}

// login
export function login(inputData) {
  return request({
    url: '/Manager/login/',
    method: 'post',
    data : {
      managerId : input.managerId ,// String Manager Id
      managerPassword : input.managerPassword ,// String Manager Password
    }
  })
}


// loginout
export function loginout(managerId) {
  return request({
    url: '/Manager/login/' + managerId,
    method: 'post',
    data : {
      'managerId' : managerId
    }
  })
}